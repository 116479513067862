<template>
  <CustomLink v-if="item.url" :to="item.url">
    <BannerGridItem
      :image="item.image.url"
      :title="item.title"
      :text="item.text"
      :padding="padding"
      :ratio="ratio"
      :shadow="shadow"
      :round="round"
      :loading="loading"
      :border-radius="borderRadius"
    />
  </CustomLink>
  <BannerGridItem
    v-else
    :image="item.image.url"
    :title="item.title"
    :text="item.text"
    :youtube="item.youtube"
    :padding="padding"
    :ratio="ratio"
    :shadow="shadow"
    :round="round"
    :loading="loading"
    :border-radius="borderRadius"
  />
</template>

<script>
import CustomLink from '~/components/elements/CustomLink';
import BannerGridItem from '~/components/elements/BannerGridItem';
export default {
  name: 'BannerGridItemWrapper',
  components: {BannerGridItem, CustomLink},
  props: {
    item: {
      type: Object,
      required: true,
    },
    ratio: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
}
</script>

