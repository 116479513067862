<template>
  <div
    class="banner-grid-item"
    :class="{ 'banner-grid-item_shadow' : shadow, 'banner-grid-item_full' : text }"
    :style="bannerStyle"
  >
    <div class="banner-grid-item__wrapper" :class="{ 'banner-grid-item_round' : round }">
      <div class="banner-grid-item__img-wrapper" :class="{ 'banner-grid-item__img-wrapper_video' : youtube }">
        <div class="banner-grid-item__img">
          <DynamicImage
            :image="image"
            :ratio="ratio"
            :loading="loading"
          />
        </div>
        <VideoIcon v-if="youtube" />
      </div>

      <div v-if="title || text" class="banner-grid-item__body" :class="{ 'banner-grid-item__body_padding' : padding }">
        <div v-if="title" class="banner-grid-item__title font font_title-m">
          {{ title }}
        </div>
        <div class="banner-grid-item__text">
          <div class="dynamic-page__content" v-html="text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImage from '~/components/elements/DynamicImage';
import VideoIcon from '~/components/elements/VideoIcon';
export default {
  name: 'BannerGridItem',
  components: {VideoIcon, DynamicImage},
  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    youtube: {
      type: String,
      default: '',
    },
    ratio: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
  computed: {
    bannerStyle() {
      return {
        '--banner-radius': this.borderRadius,
      }
    },
  },
}
</script>
